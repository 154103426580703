var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var qG,rG,tG,uG,xha,yha,zha,vG,sG;$CLJS.oG=function(a,b){return $CLJS.cc($CLJS.cb(function(c,d){var e=a.h?a.h(d):a.call(null,d);return $CLJS.Zf.j(c,e,$CLJS.Yd.g($CLJS.J.j(c,e,$CLJS.xf),d))},$CLJS.ac($CLJS.N),b))};$CLJS.pG=function(a,b){return $CLJS.cb(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.Oc(d):null},null,b)};
qG=function(a,b){$CLJS.I(a,0,null);$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);b=$CLJS.Dz(b,$CLJS.wk)?$CLJS.$F:$CLJS.Dz(b,$CLJS.Kk)?$CLJS.bD:$CLJS.Dz(b,$CLJS.Ck)?$CLJS.cD:null;return $CLJS.n(b)?$CLJS.oE(b,a):!0};
rG=function(a){a=$CLJS.y(a);$CLJS.z(a);a=$CLJS.B(a);$CLJS.z(a);a=$CLJS.B(a);a=$CLJS.oG(function(d){return $CLJS.Dz($CLJS.LD(d),$CLJS.$B)},a);var b=$CLJS.O(a);a=$CLJS.J.g(b,!1);b=$CLJS.J.g(b,!0);if($CLJS.Xj.g($CLJS.D(a),1))return"Temporal arithmetic expression must contain exactly one non-interval value";if(1>$CLJS.D(b))return"Temporal arithmetic expression must contain at least one :interval";var c=$CLJS.LD($CLJS.z(a));return $CLJS.Me(function(d){$CLJS.I(d,0,null);$CLJS.I(d,1,null);$CLJS.I(d,2,null);
var e=$CLJS.I(d,3,null);return $CLJS.n(qG(d,c))?null:["Cannot add a ",$CLJS.p.h(e)," interval to a ",$CLJS.p.h(c)," expression"].join("")},b)};
tG=function(a){return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.kr,new $CLJS.h(null,1,[$CLJS.ts,[$CLJS.p.h(a)," clause with a temporal expression and one or more :interval clauses"].join("")],null),new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Sq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.vD],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sG],null)],
null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UD],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sG],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xi,new $CLJS.h(null,1,[$CLJS.Es,function(b){b=$CLJS.O(b);b=$CLJS.J.g(b,$CLJS.aj);return["Invalid ",$CLJS.p.h(a)," clause: ",$CLJS.p.h(rG(b))].join("")}],null),$CLJS.Oe(rG)],null)],null)};
uG=function(a){return new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Sq,new $CLJS.h(null,1,[$CLJS.ts,[$CLJS.p.h(a)," clause with numeric args"].join("")],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.vD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qq,new $CLJS.h(null,1,[$CLJS.pi,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hE],null)],null)],null)],null)};
xha=function(a){return $CLJS.Md($CLJS.rE,$CLJS.cf.g(function(b){var c=$CLJS.LD(b),d=$CLJS.Dz(c,$CLJS.YD);b=d?$CLJS.oE($CLJS.nF,b):d;return $CLJS.n(b)?$CLJS.jj:c},a))};yha=function(a){a=$CLJS.pG(function(b){return!$CLJS.Dz(b,$CLJS.$B)},$CLJS.cf.g($CLJS.LD,a));return $CLJS.Dz(a,$CLJS.YD)?$CLJS.oj:a};
zha=function(a,b){return $CLJS.n($CLJS.Me(function(c){return $CLJS.Dz($CLJS.LD(c),$CLJS.$B)},b))?yha(b):$CLJS.E.g(a,$CLJS.Zr)&&$CLJS.E.g($CLJS.D(b),2)&&($CLJS.Le(function(c){return $CLJS.Dz($CLJS.LD(c),$CLJS.wk)},b)||$CLJS.Le(function(c){return $CLJS.Dz($CLJS.LD(c),$CLJS.Ck)},b))?$CLJS.$B:xha(b)};vG=new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.numbers","metabase.lib.schema.expression.arithmetic/args.numbers",-179216621);
$CLJS.wG=new $CLJS.M("lib.type-of","type-is-type-of-arithmetic-args","lib.type-of/type-is-type-of-arithmetic-args",-626871615);sG=new $CLJS.M("mbql.clause","interval","mbql.clause/interval",-990446354);$CLJS.Y(new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.temporal","metabase.lib.schema.expression.arithmetic/args.temporal",1338604923),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Vq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UD],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.M(null,"intervals","intervals",2096054013),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Iq,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,sG],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xi,new $CLJS.h(null,1,[$CLJS.ts,"Temporal arithmetic expression with valid interval units for the expression type"],null),function(a){var b=$CLJS.y(a);a=$CLJS.z(b);b=$CLJS.B(b);var c=$CLJS.LD(a);return $CLJS.Le(function(d){return qG(d,c)},b)}],null)],null));
$CLJS.Y(vG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qq,new $CLJS.h(null,1,[$CLJS.pi,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hE],null)],null)],null));
var Aha=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Sq,new $CLJS.h(null,1,[$CLJS.ts,":- clause taking the difference of two temporal expressions"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.Zr],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.vD],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UD],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UD],null)],null)],
null);$CLJS.IF.g($CLJS.Iq,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,tG($CLJS.Iq),uG($CLJS.Iq)],null));$CLJS.IF.g($CLJS.Zr,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.dr,tG($CLJS.Zr),Aha,uG($CLJS.Zr)],null));$CLJS.yE($CLJS.Jq,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kr,vG],null)]));$CLJS.yE($CLJS.DF,$CLJS.H([$CLJS.Zr,$CLJS.KC,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kr,vG],null)]));
for(var xG=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Iq,$CLJS.Zr,$CLJS.Jq],null)),yG=null,zG=0,AG=0;;)if(AG<zG){var Bha=yG.X(null,AG);$CLJS.pE(Bha,$CLJS.wG);AG+=1}else{var BG=$CLJS.y(xG);if(BG){var CG=BG;if($CLJS.vd(CG)){var DG=$CLJS.ic(CG),Cha=$CLJS.jc(CG),Dha=DG,Eha=$CLJS.D(DG);xG=Cha;yG=Dha;zG=Eha}else{var Fha=$CLJS.z(CG);$CLJS.pE(Fha,$CLJS.wG);xG=$CLJS.B(CG);yG=null;zG=0}AG=0}else break}
$CLJS.KD.m(null,$CLJS.wG,function(a){var b=$CLJS.y(a);a=$CLJS.z(b);b=$CLJS.B(b);$CLJS.z(b);b=$CLJS.B(b);return zha(a,b)});$CLJS.wE($CLJS.JE,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hE],null)],null)]));$CLJS.pE($CLJS.JE,$CLJS.bE);
for(var EG=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FF,$CLJS.AE,$CLJS.XE],null)),FG=null,GG=0,HG=0;;)if(HG<GG){var Gha=FG.X(null,HG);$CLJS.wE(Gha,$CLJS.H([$CLJS.Zr,$CLJS.KC,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hE],null)],null)]));HG+=1}else{var IG=$CLJS.y(EG);if(IG){var JG=IG;if($CLJS.vd(JG)){var KG=$CLJS.ic(JG),Hha=$CLJS.jc(JG),Iha=KG,Jha=$CLJS.D(KG);EG=Hha;FG=Iha;GG=Jha}else{var Kha=$CLJS.z(JG);$CLJS.wE(Kha,$CLJS.H([$CLJS.Zr,$CLJS.KC,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hE],null)],null)]));EG=$CLJS.B(JG);FG=null;GG=0}HG=0}else break}
for(var LG=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EE,$CLJS.hF,$CLJS.oF],null)),MG=null,NG=0,OG=0;;)if(OG<NG){var Lha=MG.X(null,OG);$CLJS.wE(Lha,$CLJS.H([$CLJS.Zr,$CLJS.Vi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hE],null)],null)]));OG+=1}else{var PG=$CLJS.y(LG);if(PG){var QG=PG;if($CLJS.vd(QG)){var RG=$CLJS.ic(QG),Mha=$CLJS.jc(QG),Nha=RG,Oha=$CLJS.D(RG);LG=Mha;MG=Nha;NG=Oha}else{var Pha=$CLJS.z(QG);$CLJS.wE(Pha,$CLJS.H([$CLJS.Zr,$CLJS.Vi,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hE],null)],null)]));LG=$CLJS.B(QG);MG=null;NG=0}OG=0}else break}$CLJS.wE($CLJS.GE,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hE],null)],null)]));
$CLJS.KD.m(null,$CLJS.GE,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.Dz($CLJS.LD(b),$CLJS.Vi)&&$CLJS.Dz($CLJS.LD(a),$CLJS.Vi)?$CLJS.Vi:$CLJS.KC});