var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.convert.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.expression.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.util.js");require("./metabase.mbql.util.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var V0,W0,Opa,Ppa;$CLJS.S0=function(a,b){return $CLJS.D(a)<=$CLJS.D(b)&&$CLJS.Le(function(c){return $CLJS.Cd(b,c)},a)};$CLJS.T0=function(a,b){return $CLJS.Jz($CLJS.Oe(a),b)};$CLJS.U0=function(a){var b;a:for(b=a;;)if($CLJS.sd(b))b=$CLJS.Ei.h(b);else{b=$CLJS.Ad(b)?b:null;break a}if($CLJS.n(b))return b;throw Error($CLJS.gD("Not something with an ID: {0}",$CLJS.H([$CLJS.xh.l($CLJS.H([a]))])));};
V0=function(a){if(null!=a&&null!=a.sg)a=a.sg(a);else{var b=V0[$CLJS.va(null==a?null:a)];if(null!=b)a=b.h?b.h(a):b.call(null,a);else if(b=V0._,null!=b)a=b.h?b.h(a):b.call(null,a);else throw $CLJS.$a("MetadataProvider.database",a);}return a};W0=function(a,b){if(null!=a&&null!=a.tg)a=a.tg(a,b);else{var c=W0[$CLJS.va(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=W0._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.$a("MetadataProvider.field",a);}return a};$CLJS.X0=function(a){return V0($CLJS.a_(a))};
$CLJS.Y0=function(a,b){return W0($CLJS.a_(a),b)};$CLJS.Z0=function(a){var b=$CLJS.CV(a,0),c=$CLJS.O(b),d=$CLJS.J.g(c,$CLJS.cO),e=$CLJS.J.g(c,$CLJS.LU);return $CLJS.zd(function(){var f=$CLJS.X0(a);$CLJS.n(f)?(f=$CLJS.O(f),f=$CLJS.J.g(f,$CLJS.Ei),f=$CLJS.E.g($CLJS.SO.h(a),f)):f=null;if($CLJS.n(f)){f=$CLJS.n(d)?$CLJS.b_(a,d):d;if($CLJS.n(f))return f;f=$CLJS.n(e)?$CLJS.d_(a,e):e;return $CLJS.n(f)?f:$CLJS.E.g($CLJS.Vs.h(c),$CLJS.ZU)&&$CLJS.E.g($CLJS.ZY,$CLJS.$Y.h($CLJS.X0(a)))}return f}())};
Opa=new $CLJS.M(null,"is-native","is-native",-1797717934);Ppa=new $CLJS.M(null,"is-editable","is-editable",269018020);$CLJS.P0.m(null,$CLJS.eV,function(a){return $CLJS.O0(a,new $CLJS.h(null,2,[$CLJS.Oi,$CLJS.mh,$CLJS.oK,$CLJS.Qe($CLJS.ak,$CLJS.P0)],null))});$CLJS.Q_.m(null,$CLJS.eV,function(){throw $CLJS.Uh("You can't calculate a metadata map for a query! Use lib.metadata.calculation/returned-columns-method instead.",$CLJS.N);});$CLJS.T_.m(null,$CLJS.eV,function(a,b,c,d){return $CLJS.U_.v(a,b,$CLJS.CV(c,b),d)});$CLJS.K_.m(null,$CLJS.eV,function(a,b,c,d){return $CLJS.j_.v(a,b,$CLJS.CV(c,b),d)});
$CLJS.S_.m(null,$CLJS.eV,function(a,b,c){a=$CLJS.CV(c,0);a=$CLJS.E.g($CLJS.Vs.h(a),$CLJS.ZU);return new $CLJS.h(null,2,[Opa,a,Ppa,$CLJS.Z0(c)],null)});$CLJS.$0=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.Mi,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.query","can-run-method"),function(f){return $CLJS.Vs.h($CLJS.CV(f,0))},e,a,b,c,d)}();$CLJS.$0.m(null,$CLJS.Oh,function(){return!0});
$CLJS.a1=function(){function a(d,e,f){return new $CLJS.h(null,4,[$CLJS.Vs,$CLJS.eV,$CLJS.hD,$CLJS.a_(e),$CLJS.SO,d,$CLJS.oK,f],null)}function b(d,e){var f=$CLJS.Ei.h($CLJS.X0(d));return c.j?c.j(f,d,e):c.call(null,f,d,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.b1=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Mi,$CLJS.fB],null),$CLJS.Mi,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.query","query-method"),function(f,k){return $CLJS.WA(k)},e,a,b,c,d)}();$CLJS.b1.m(null,$CLJS.pA,function(a,b){b=$CLJS.UX.h(b);return $CLJS.a1.g(a,$CLJS.oK.h(b))});
$CLJS.b1.m(null,$CLJS.eV,function(a,b){var c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.QX);var d=$CLJS.a_(a),e=$CLJS.Rj.g($CLJS.R.j(c,$CLJS.hD,d),$CLJS.QX);a=$CLJS.oK.h(e);return $CLJS.n(b)?$CLJS.R.j(e,$CLJS.oK,$CLJS.Wf.j($CLJS.xf,$CLJS.cf.h(function(f){var k=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return function u(m,t){try{if($CLJS.ud(t)&&3===$CLJS.D(t))try{var v=$CLJS.F(t,0);if($CLJS.ce(v,$CLJS.jF))try{var x=$CLJS.F(t,1);if($CLJS.n($CLJS.eD($CLJS.sd,$CLJS.Oe($CLJS.zz($CLJS.tA,$CLJS.mi)))(x)))try{var A=$CLJS.F(t,
2);if($CLJS.n($CLJS.eD($CLJS.Yk,$CLJS.ql)(A))){var C=$CLJS.F(t,2),G=$CLJS.F(t,1),K=$CLJS.Bl($CLJS.Y0(d,C),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tA,$CLJS.mi],null));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jF,$CLJS.qk.l($CLJS.H([K,G])),C],null)}throw $CLJS.Z;}catch(da){if(da instanceof Error){var S=da;if(S===$CLJS.Z)throw $CLJS.Z;throw S;}throw da;}else throw $CLJS.Z;}catch(da){if(da instanceof Error){S=da;if(S===$CLJS.Z)throw $CLJS.Z;throw S;}throw da;}else throw $CLJS.Z;}catch(da){if(da instanceof
Error)if(S=da,S===$CLJS.Z)try{if(v=$CLJS.F(t,0),$CLJS.ce(v,$CLJS.qA))try{x=$CLJS.F(t,1);if($CLJS.n($CLJS.eD($CLJS.sd,$CLJS.Oe($CLJS.zz($CLJS.tA,$CLJS.mi)))(x))){G=$CLJS.F(t,1);var X=$CLJS.F(t,2);K=function(){try{return $CLJS.T0(function(oa){return $CLJS.E.g($CLJS.Zi,oa)},$CLJS.Bl($CLJS.dd($CLJS.Gpa.j(e,k,X)),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tA,$CLJS.mi],null)))}catch(oa){return null}}();return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qA,$CLJS.qk.l($CLJS.H([K,G])),X],null)}throw $CLJS.Z;}catch(oa){if(oa instanceof
Error){var T=oa;if(T===$CLJS.Z)throw $CLJS.Z;throw T;}throw oa;}else throw $CLJS.Z;}catch(oa){if(oa instanceof Error){T=oa;if(T===$CLJS.Z)throw $CLJS.Z;throw T;}throw oa;}else throw S;else throw da;}else throw $CLJS.Z;}catch(da){if(da instanceof Error){S=da;if(S===$CLJS.Z)return $CLJS.VV(u,m,t);throw S;}throw da;}}($CLJS.xf,f)}),$CLJS.Al($CLJS.xr,a))):e});
$CLJS.b1.m(null,$CLJS.QJ,function(a,b){return $CLJS.a1.g(a,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.SU,$CLJS.cO,$CLJS.U0(b)],null)],null))});$CLJS.b1.m(null,$CLJS.fK,function(a,b){return $CLJS.a1.g(a,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.SU,$CLJS.LU,$CLJS.U0(b)],null)],null))});