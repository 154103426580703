var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.schema.common.js");require("./metabase.mbql.predicates.js");require("./metabase.mbql.schema.js");require("./metabase.mbql.schema.helpers.js");require("./cljs.core.match.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var qma,uU;$CLJS.qU=function(a){return a instanceof Error?a.message:null};
qma=function(a,b,c){if($CLJS.Wd(c)){var d=$CLJS.Ne.g($CLJS.V,$CLJS.cf.g(a,c));return b.h?b.h(d):b.call(null,d)}return $CLJS.uf(c)?(d=new $CLJS.Sf(function(){var e=$CLJS.Bb(c);return a.h?a.h(e):a.call(null,e)}(),function(){var e=$CLJS.Cb(c);return a.h?a.h(e):a.call(null,e)}()),b.h?b.h(d):b.call(null,d)):$CLJS.yd(c)?(d=$CLJS.ch($CLJS.cf.g(a,c)),b.h?b.h(d):b.call(null,d)):$CLJS.td(c)?(d=$CLJS.cb(function(e,f){return $CLJS.Yd.g(e,a.h?a.h(f):a.call(null,f))},c,c),b.h?b.h(d):b.call(null,d)):$CLJS.pd(c)?
(d=$CLJS.Wf.g($CLJS.fd(c),$CLJS.cf.g(a,c)),b.h?b.h(d):b.call(null,d)):b.h?b.h(c):b.call(null,c)};$CLJS.sU=function(a){function b(c){var d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);return"string"===typeof d?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mh.h(d),c],null):new $CLJS.P(null,2,5,$CLJS.Q,[d,c],null)}return $CLJS.rU(function(c){return $CLJS.sd(c)?$CLJS.Wf.g($CLJS.N,$CLJS.cf.g(b,c)):c},a)};
$CLJS.tU=function(a){var b=new $CLJS.Ca;for(a=$CLJS.y(a);;)if(null!=a)b=b.append($CLJS.p.h($CLJS.z(a))),a=$CLJS.B(a);else return b.toString()};$CLJS.vU=function(a,b,c){a=$CLJS.Gz(a,/''/,"'");var d=$CLJS.Ns(a,uU);d=$CLJS.E.g($CLJS.D(d),1)?new $CLJS.P(null,2,5,$CLJS.Q,[a,""],null):d;a=$CLJS.eh(/.*\{0\}.*/,a);return $CLJS.Gs.ngettext($CLJS.Gs.msgid($CLJS.sk(d),$CLJS.n(a)?c:""),$CLJS.Gz($CLJS.Gz(b,/''/,"'"),uU,$CLJS.p.h(c)),c)};
$CLJS.wU=function(a,b){return $CLJS.cb(function(c,d){var e=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return $CLJS.Cd(a,e)?$CLJS.R.j(c,d,$CLJS.J.g(a,e)):c},$CLJS.Ne.j($CLJS.Rj,a,$CLJS.Ng(b)),b)};$CLJS.xU=function(a,b,c){return $CLJS.sd(c)?$CLJS.df(function(d){var e=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);e=$CLJS.Yd.g(b,e);return a.g?a.g(e,d):a.call(null,e,d)},$CLJS.H([c])):$CLJS.rd(c)?$CLJS.df($CLJS.Qe(a,$CLJS.z(c)instanceof $CLJS.M?$CLJS.Yd.g(b,$CLJS.z(c)):b),$CLJS.H([c])):null};
$CLJS.rU=function rU(a,b){return qma($CLJS.Qe(rU,a),a,b)};uU=/\{0\}/;$CLJS.yU=new $CLJS.M(null,"unique-alias-fn","unique-alias-fn",32555420);$CLJS.zU=new $CLJS.M(null,"binning-strategy","binning-strategy",2063329158);$CLJS.AU=new $CLJS.M("clojure.core.match","not-found","clojure.core.match/not-found",1553053780);$CLJS.BU=new $CLJS.M(null,"name-key-fn","name-key-fn",-1634839199);var rma=$CLJS.zg([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iI,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TH,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$A,$CLJS.XH],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$A,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gI,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bI,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dI,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.MH,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eI,null],null),new $CLJS.P(null,2,5,
$CLJS.Q,[$CLJS.$A,$CLJS.QH],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$A,$CLJS.aI],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SH,null],null)],[$CLJS.xk,$CLJS.WF,$CLJS.jI,$CLJS.WH,$CLJS.vk,$CLJS.Dk,$CLJS.Bk,$CLJS.Hk,$CLJS.yk,$CLJS.WH,$CLJS.kI,$CLJS.TF]),DU;$CLJS.Wg($CLJS.cf.g($CLJS.z,$CLJS.Ng(rma)));var CU,sma=$CLJS.Se($CLJS.N),tma=$CLJS.Se($CLJS.N),uma=$CLJS.Se($CLJS.N),vma=$CLJS.Se($CLJS.N),wma=$CLJS.J.j($CLJS.N,$CLJS.Mi,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));
CU=new $CLJS.Ph($CLJS.qh.g("metabase.mbql.util","negate*"),$CLJS.z,wma,sma,tma,uma,vma);CU.m(null,$CLJS.fr,function(a){$CLJS.I(a,0,null);return $CLJS.I(a,1,null)});CU.m(null,$CLJS.kr,function(a){a=$CLJS.y(a);$CLJS.z(a);a=$CLJS.B(a);return $CLJS.Wf.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.dr],null),$CLJS.cf.g(CU,a))});CU.m(null,$CLJS.dr,function(a){a=$CLJS.y(a);$CLJS.z(a);a=$CLJS.B(a);return $CLJS.Wf.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.kr],null),$CLJS.cf.g(CU,a))});
CU.m(null,$CLJS.Gq,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zF,b,a],null)});CU.m(null,$CLJS.zF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gq,b,a],null)});CU.m(null,$CLJS.zq,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fq,b,a],null)});
CU.m(null,$CLJS.Dq,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bq,b,a],null)});CU.m(null,$CLJS.Bq,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Dq,b,a],null)});CU.m(null,$CLJS.Fq,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zq,b,a],null)});
CU.m(null,$CLJS.LE,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Dq,b,c],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zq,b,a],null)],null)});CU.m(null,$CLJS.iF,function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,a],null)});CU.m(null,$CLJS.aF,function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,a],null)});
CU.m(null,$CLJS.uF,function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,a],null)});
DU=function DU(a){return $CLJS.sd(a)?$CLJS.Be($CLJS.Wf.g($CLJS.N,function(){return function e(d){return new $CLJS.ie(null,function(){for(var f=d;;)if(f=$CLJS.y(f)){if($CLJS.vd(f)){var k=$CLJS.ic(f),l=$CLJS.D(k),m=$CLJS.le(l);a:for(var t=0;;)if(t<l){var u=$CLJS.hd(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=DU.h?DU.h(u):DU.call(null,u);null!=u&&m.add(new $CLJS.P(null,2,5,$CLJS.Q,[v,u],null));t+=1}else{k=!0;break a}return k?$CLJS.oe($CLJS.qe(m),e($CLJS.jc(f))):$CLJS.oe($CLJS.qe(m),null)}k=$CLJS.z(f);
m=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=DU.h?DU.h(k):DU.call(null,k);if(null!=k)return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[m,k],null),e($CLJS.Hc(f)));f=$CLJS.Hc(f)}else return null},null,null)}(a)}())):$CLJS.rd(a)?$CLJS.Be($CLJS.Wf.g($CLJS.fd(a),$CLJS.ff($CLJS.Sa,$CLJS.cf.g(DU,a)))):a};
$CLJS.EU=function(){function a(c,d,e){var f=null;if(2<arguments.length){f=0;for(var k=Array(arguments.length-2);f<k.length;)k[f]=arguments[f+2],++f;f=new $CLJS.w(k,0,null)}return b.call(this,c,d,f)}function b(c,d,e){var f=$CLJS.I(c,0,null),k=$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);d=$CLJS.Be(DU($CLJS.Ne.j(d,c,e)));return $CLJS.n(d)?new $CLJS.P(null,3,5,$CLJS.Q,[f,k,d],null):$CLJS.E.g(f,$CLJS.jF)?new $CLJS.P(null,3,5,$CLJS.Q,[f,k,null],null):new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null)}a.A=2;a.B=function(c){var d=
$CLJS.z(c);c=$CLJS.B(c);var e=$CLJS.z(c);c=$CLJS.Hc(c);return b(d,e,c)};a.l=b;return a}();